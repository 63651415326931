
export const countryList =
{
    "countries": [
      {
        "code": "93", 
        "name": "AFG  93"
      }, 
      {
        "code": "355", 
        "name": "ALB  355"
      }, 
      {
        "code": "213", 
        "name": "DZA  213"
      }, 
      {
        "code": "1-684", 
        "name": "ASM  1-684"
      }, 
      {
        "code": "376", 
        "name": "AND  376"
      }, 
      {
        "code": "244", 
        "name": "AGO  244"
      }, 
      {
        "code": "1-264", 
        "name": "AIA  1-264"
      }, 
      {
        "code": "672", 
        "name": "ATA  672"
      }, 
      {
        "code": "1-268", 
        "name": "ATG  1-268"
      }, 
      {
        "code": "54", 
        "name": "ARG  54"
      }, 
      {
        "code": "374", 
        "name": "ARM  374"
      }, 
      {
        "code": "297", 
        "name": "ABW  297"
      }, 
      {
        "code": "61", 
        "name": "AUS  61"
      }, 
      {
        "code": "43", 
        "name": "AUT  43"
      }, 
      {
        "code": "994", 
        "name": "AZE  994"
      }, 
      {
        "code": "1-242", 
        "name": "BHS  1-242"
      }, 
      {
        "code": "973", 
        "name": "BHR  973"
      }, 
      {
        "code": "880", 
        "name": "BGD  880"
      }, 
      {
        "code": "1-246", 
        "name": "BRB  1-246"
      }, 
      {
        "code": "375", 
        "name": "BLR  375"
      }, 
      {
        "code": "32", 
        "name": "BEL  32"
      }, 
      {
        "code": "501", 
        "name": "BLZ  501"
      }, 
      {
        "code": "229", 
        "name": "BEN  229"
      }, 
      {
        "code": "1-441", 
        "name": "BMU  1-441"
      }, 
      {
        "code": "975", 
        "name": "BTN  975"
      }, 
      {
        "code": "591", 
        "name": "BOL  591"
      }, 
      {
        "code": "387", 
        "name": "BIH  387"
      }, 
      {
        "code": "267", 
        "name": "BWA  267"
      }, 
      {
        "code": "55", 
        "name": "BRA  55"
      }, 
      {
        "code": "246", 
        "name": "IOT  246"
      }, 
      {
        "code": "1-284", 
        "name": "VGB  1-284"
      }, 
      {
        "code": "673", 
        "name": "BRN  673"
      }, 
      {
        "code": "359", 
        "name": "BGR  359"
      }, 
      {
        "code": "226", 
        "name": "BFA  226"
      }, 
      {
        "code": "257", 
        "name": "BDI  257"
      }, 
      {
        "code": "855", 
        "name": "KHM  855"
      }, 
      {
        "code": "237", 
        "name": "CMR  237"
      }, 
      {
        "code": "1", 
        "name": "CAN  1"
      }, 
      {
        "code": "238", 
        "name": "CPV  238"
      }, 
      {
        "code": "1-345", 
        "name": "CYM  1-345"
      }, 
      {
        "code": "236", 
        "name": "CAF  236"
      }, 
      {
        "code": "235", 
        "name": "TCD  235"
      }, 
      {
        "code": "56", 
        "name": "CHL  56"
      }, 
      {
        "code": "86", 
        "name": "CHN  86"
      }, 
      {
        "code": "61", 
        "name": "CXR  61"
      }, 
      {
        "code": "61", 
        "name": "CCK  61"
      }, 
      {
        "code": "57", 
        "name": "COL  57"
      }, 
      {
        "code": "269", 
        "name": "COM  269"
      }, 
      {
        "code": "682", 
        "name": "COK  682"
      }, 
      {
        "code": "506", 
        "name": "CRI  506"
      }, 
      {
        "code": "385", 
        "name": "HRV  385"
      }, 
      {
        "code": "53", 
        "name": "CUB  53"
      }, 
      {
        "code": "599", 
        "name": "CUW  599"
      }, 
      {
        "code": "357", 
        "name": "CYP  357"
      }, 
      {
        "code": "420", 
        "name": "CZE  420"
      }, 
      {
        "code": "243", 
        "name": "COD  243"
      }, 
      {
        "code": "45", 
        "name": "DNK  45"
      }, 
      {
        "code": "253", 
        "name": "DJI  253"
      }, 
      {
        "code": "1-767", 
        "name": "DMA  1-767"
      }, 
      {
        "code": "1-809, 1-829, 1-849", 
        "name": "DOM  1-809, 1-829, 1-849"
      }, 
      {
        "code": "670", 
        "name": "TLS  670"
      }, 
      {
        "code": "593", 
        "name": "ECU  593"
      }, 
      {
        "code": "20", 
        "name": "EGY  20"
      }, 
      {
        "code": "503", 
        "name": "SLV  503"
      }, 
      {
        "code": "240", 
        "name": "GNQ  240"
      }, 
      {
        "code": "291", 
        "name": "ERI  291"
      }, 
      {
        "code": "372", 
        "name": "EST  372"
      }, 
      {
        "code": "251", 
        "name": "ETH  251"
      }, 
      {
        "code": "500", 
        "name": "FLK  500"
      }, 
      {
        "code": "298", 
        "name": "FRO  298"
      }, 
      {
        "code": "679", 
        "name": "FJI  679"
      }, 
      {
        "code": "358", 
        "name": "FIN  358"
      }, 
      {
        "code": "33", 
        "name": "FRA  33"
      }, 
      {
        "code": "689", 
        "name": "PYF  689"
      }, 
      {
        "code": "241", 
        "name": "GAB  241"
      }, 
      {
        "code": "220", 
        "name": "GMB  220"
      }, 
      {
        "code": "995", 
        "name": "GEO  995"
      }, 
      {
        "code": "49", 
        "name": "DEU  49"
      }, 
      {
        "code": "233", 
        "name": "GHA  233"
      }, 
      {
        "code": "350", 
        "name": "GIB  350"
      }, 
      {
        "code": "30", 
        "name": "GRC  30"
      }, 
      {
        "code": "299", 
        "name": "GRL  299"
      }, 
      {
        "code": "1-473", 
        "name": "GRD  1-473"
      }, 
      {
        "code": "1-671", 
        "name": "GUM  1-671"
      }, 
      {
        "code": "502", 
        "name": "GTM  502"
      }, 
      {
        "code": "44-1481", 
        "name": "GGY  44-1481"
      }, 
      {
        "code": "224", 
        "name": "GIN  224"
      }, 
      {
        "code": "245", 
        "name": "GNB  245"
      }, 
      {
        "code": "592", 
        "name": "GUY  592"
      }, 
      {
        "code": "509", 
        "name": "HTI  509"
      }, 
      {
        "code": "504", 
        "name": "HND  504"
      }, 
      {
        "code": "852", 
        "name": "HKG  852"
      }, 
      {
        "code": "36", 
        "name": "HUN  36"
      }, 
      {
        "code": "354", 
        "name": "ISL  354"
      }, 
      {
        "code": "91", 
        "name": "IND  91"
      }, 
      {
        "code": "62", 
        "name": "IDN  62"
      }, 
      {
        "code": "98", 
        "name": "IRN  98"
      }, 
      {
        "code": "964", 
        "name": "IRQ  964"
      }, 
      {
        "code": "353", 
        "name": "IRL  353"
      }, 
      {
        "code": "44-1624", 
        "name": "IMN  44-1624"
      }, 
      {
        "code": "972", 
        "name": "ISR  972"
      }, 
      {
        "code": "39", 
        "name": "ITA  39"
      }, 
      {
        "code": "225", 
        "name": "CIV  225"
      }, 
      {
        "code": "1-876", 
        "name": "JAM  1-876"
      }, 
      {
        "code": "81", 
        "name": "JPN  81"
      }, 
      {
        "code": "44-1534", 
        "name": "JEY  44-1534"
      }, 
      {
        "code": "962", 
        "name": "JOR  962"
      }, 
      {
        "code": "7", 
        "name": "KAZ  7"
      }, 
      {
        "code": "254", 
        "name": "KEN  254"
      }, 
      {
        "code": "686", 
        "name": "KIR  686"
      }, 
      {
        "code": "383", 
        "name": "XKX  383"
      }, 
      {
        "code": "965", 
        "name": "KWT  965"
      }, 
      {
        "code": "996", 
        "name": "KGZ  996"
      }, 
      {
        "code": "856", 
        "name": "LAO  856"
      }, 
      {
        "code": "371", 
        "name": "LVA  371"
      }, 
      {
        "code": "961", 
        "name": "LBN  961"
      }, 
      {
        "code": "266", 
        "name": "LSO  266"
      }, 
      {
        "code": "231", 
        "name": "LBR  231"
      }, 
      {
        "code": "218", 
        "name": "LBY  218"
      }, 
      {
        "code": "423", 
        "name": "LIE  423"
      }, 
      {
        "code": "370", 
        "name": "LTU  370"
      }, 
      {
        "code": "352", 
        "name": "LUX  352"
      }, 
      {
        "code": "853", 
        "name": "MAC  853"
      }, 
      {
        "code": "389", 
        "name": "MKD  389"
      }, 
      {
        "code": "261", 
        "name": "MDG  261"
      }, 
      {
        "code": "265", 
        "name": "MWI  265"
      }, 
      {
        "code": "60", 
        "name": "MYS  60"
      }, 
      {
        "code": "960", 
        "name": "MDV  960"
      }, 
      {
        "code": "223", 
        "name": "MLI  223"
      }, 
      {
        "code": "356", 
        "name": "MLT  356"
      }, 
      {
        "code": "692", 
        "name": "MHL  692"
      }, 
      {
        "code": "222", 
        "name": "MRT  222"
      }, 
      {
        "code": "230", 
        "name": "MUS  230"
      }, 
      {
        "code": "262", 
        "name": "MYT  262"
      }, 
      {
        "code": "52", 
        "name": "MEX  52"
      }, 
      {
        "code": "691", 
        "name": "FSM  691"
      }, 
      {
        "code": "373", 
        "name": "MDA  373"
      }, 
      {
        "code": "377", 
        "name": "MCO  377"
      }, 
      {
        "code": "976", 
        "name": "MNG  976"
      }, 
      {
        "code": "382", 
        "name": "MNE  382"
      }, 
      {
        "code": "1-664", 
        "name": "MSR  1-664"
      }, 
      {
        "code": "212", 
        "name": "MAR  212"
      }, 
      {
        "code": "258", 
        "name": "MOZ  258"
      }, 
      {
        "code": "95", 
        "name": "MMR  95"
      }, 
      {
        "code": "264", 
        "name": "NAM  264"
      }, 
      {
        "code": "674", 
        "name": "NRU  674"
      }, 
      {
        "code": "977", 
        "name": "NPL  977"
      }, 
      {
        "code": "31", 
        "name": "NLD  31"
      }, 
      {
        "code": "599", 
        "name": "ANT  599"
      }, 
      {
        "code": "687", 
        "name": "NCL  687"
      }, 
      {
        "code": "64", 
        "name": "NZL  64"
      }, 
      {
        "code": "505", 
        "name": "NIC  505"
      }, 
      {
        "code": "227", 
        "name": "NER  227"
      }, 
      {
        "code": "234", 
        "name": "NGA  234"
      }, 
      {
        "code": "683", 
        "name": "NIU  683"
      }, 
      {
        "code": "850", 
        "name": "PRK  850"
      }, 
      {
        "code": "1-670", 
        "name": "MNP  1-670"
      }, 
      {
        "code": "47", 
        "name": "NOR  47"
      }, 
      {
        "code": "968", 
        "name": "OMN  968"
      }, 
      {
        "code": "92", 
        "name": "PAK  92"
      }, 
      {
        "code": "680", 
        "name": "PLW  680"
      }, 
      {
        "code": "970", 
        "name": "PSE  970"
      }, 
      {
        "code": "507", 
        "name": "PAN  507"
      }, 
      {
        "code": "675", 
        "name": "PNG  675"
      }, 
      {
        "code": "595", 
        "name": "PRY  595"
      }, 
      {
        "code": "51", 
        "name": "PER  51"
      }, 
      {
        "code": "63", 
        "name": "PHL  63"
      }, 
      {
        "code": "64", 
        "name": "PCN  64"
      }, 
      {
        "code": "48", 
        "name": "POL  48"
      }, 
      {
        "code": "351", 
        "name": "PRT  351"
      }, 
      {
        "code": "1-787, 1-939", 
        "name": "PRI  1-787, 1-939"
      }, 
      {
        "code": "974", 
        "name": "QAT  974"
      }, 
      {
        "code": "242", 
        "name": "COG  242"
      }, 
      {
        "code": "262", 
        "name": "REU  262"
      }, 
      {
        "code": "40", 
        "name": "ROU  40"
      }, 
      {
        "code": "7", 
        "name": "RUS  7"
      }, 
      {
        "code": "250", 
        "name": "RWA  250"
      }, 
      {
        "code": "590", 
        "name": "BLM  590"
      }, 
      {
        "code": "290", 
        "name": "SHN  290"
      }, 
      {
        "code": "1-869", 
        "name": "KNA  1-869"
      }, 
      {
        "code": "1-758", 
        "name": "LCA  1-758"
      }, 
      {
        "code": "590", 
        "name": "MAF  590"
      }, 
      {
        "code": "508", 
        "name": "SPM  508"
      }, 
      {
        "code": "1-784", 
        "name": "VCT  1-784"
      }, 
      {
        "code": "685", 
        "name": "WSM  685"
      }, 
      {
        "code": "378", 
        "name": "SMR  378"
      }, 
      {
        "code": "239", 
        "name": "STP  239"
      }, 
      {
        "code": "966", 
        "name": "SAU  966"
      }, 
      {
        "code": "221", 
        "name": "SEN  221"
      }, 
      {
        "code": "381", 
        "name": "SRB  381"
      }, 
      {
        "code": "248", 
        "name": "SYC  248"
      }, 
      {
        "code": "232", 
        "name": "SLE  232"
      }, 
      {
        "code": "65", 
        "name": "SGP  65"
      }, 
      {
        "code": "1-721", 
        "name": "SXM  1-721"
      }, 
      {
        "code": "421", 
        "name": "SVK  421"
      }, 
      {
        "code": "386", 
        "name": "SVN  386"
      }, 
      {
        "code": "677", 
        "name": "SLB  677"
      }, 
      {
        "code": "252", 
        "name": "SOM  252"
      }, 
      {
        "code": "27", 
        "name": "ZAF  27"
      }, 
      {
        "code": "82", 
        "name": "KOR  82"
      }, 
      {
        "code": "211", 
        "name": "SSD  211"
      }, 
      {
        "code": "34", 
        "name": "ESP  34"
      }, 
      {
        "code": "94", 
        "name": "LKA  94"
      }, 
      {
        "code": "249", 
        "name": "SDN  249"
      }, 
      {
        "code": "597", 
        "name": "SUR  597"
      }, 
      {
        "code": "47", 
        "name": "SJM  47"
      }, 
      {
        "code": "268", 
        "name": "SWZ  268"
      }, 
      {
        "code": "46", 
        "name": "SWE  46"
      }, 
      {
        "code": "41", 
        "name": "CHE  41"
      }, 
      {
        "code": "963", 
        "name": "SYR  963"
      }, 
      {
        "code": "886", 
        "name": "TWN  886"
      }, 
      {
        "code": "992", 
        "name": "TJK  992"
      }, 
      {
        "code": "255", 
        "name": "TZA  255"
      }, 
      {
        "code": "66", 
        "name": "THA  66"
      }, 
      {
        "code": "228", 
        "name": "TGO  228"
      }, 
      {
        "code": "690", 
        "name": "TKL  690"
      }, 
      {
        "code": "676", 
        "name": "TON  676"
      }, 
      {
        "code": "1-868", 
        "name": "TTO  1-868"
      }, 
      {
        "code": "216", 
        "name": "TUN  216"
      }, 
      {
        "code": "90", 
        "name": "TUR  90"
      }, 
      {
        "code": "993", 
        "name": "TKM  993"
      }, 
      {
        "code": "1-649", 
        "name": "TCA  1-649"
      }, 
      {
        "code": "688", 
        "name": "TUV  688"
      }, 
      {
        "code": "1-340", 
        "name": "VIR  1-340"
      }, 
      {
        "code": "256", 
        "name": "UGA  256"
      }, 
      {
        "code": "380", 
        "name": "UKR  380"
      }, 
      {
        "code": "971", 
        "name": "ARE  971"
      }, 
      {
        "code": "44", 
        "name": "GBR  44"
      }, 
      {
        "code": "1", 
        "name": "USA  1"
      }, 
      {
        "code": "598", 
        "name": "URY  598"
      }, 
      {
        "code": "998", 
        "name": "UZB  998"
      }, 
      {
        "code": "678", 
        "name": "VUT  678"
      }, 
      {
        "code": "379", 
        "name": "VAT  379"
      }, 
      {
        "code": "58", 
        "name": "VEN  58"
      }, 
      {
        "code": "84", 
        "name": "VNM  84"
      }, 
      {
        "code": "681", 
        "name": "WLF  681"
      }, 
      {
        "code": "212", 
        "name": "ESH  212"
      }, 
      {
        "code": "967", 
        "name": "YEM  967"
      }, 
      {
        "code": "260", 
        "name": "ZMB  260"
      }, 
      {
        "code": "263", 
        "name": "ZWE  263"
      }
    ]
  }
  
  