import React from 'react';

import ContactUs from "../components/contact-us"
import Layout from "../components/layout"


export default function ContactUsPage({ location, data }) {
    return (
        <Layout location={location}>
            <ContactUs data={data} />
        </Layout>
    )
}
